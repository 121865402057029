import '../BladeInstance.scss';
import variables from '../../../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { AdditionalOptions } from '../../../../assets/icons/Icons';
import DropDownButton from '../../../../components/DropDownButton';
import AnalogGauge from '../../../../components/AnalogGauge';
import { selectBladeComponentInfo, selectBladeLiveDataByComponentInfo, selectBladeRuntimeInformation, selectBladeUniqueConfigurationByName, setBladeRuntimeProperty } from '../../../../redux/entities/service/Blade';
import Countdown from '../../../../components/Countdown';



const BladeInstance_StatusContent_IrrigationCell = ({ bladeId }) => {

    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
    const dispatch = useDispatch()

    const [liquidPressureComponentInfo] 
      = useSelector((state) => selectBladeComponentInfo(state, bladeId, "PrimaryLiquidSystemPressureTransducer", ["pressure"]), _.isEqual)

    let liquidPressure = useSelector((state) => selectBladeLiveDataByComponentInfo(state, bladeId, liquidPressureComponentInfo), _.isEqual)
  
    const [liquidStatusRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_reservoir_control_state"), _.isEqual)
    const [liquidSubstatusRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_reservoir_subcontrol_state"), _.isEqual)
    const liquidStatus = liquidStatusRuntimeInformation ? liquidStatusRuntimeInformation : "Idle"

    const [irrigationTestStartedOnRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_system_irrigation_test_started_on"), _.isEqual)
    const [irrigationTestDurationRuntimeInformation] = useSelector((state) => selectBladeRuntimeInformation(state, bladeId, "primary_system_irrigation_test_duration"), _.isEqual)
    const irrigationTestFromTime = (irrigationTestStartedOnRuntimeInformation && irrigationTestDurationRuntimeInformation) ? parseFloat(irrigationTestStartedOnRuntimeInformation) + parseFloat(irrigationTestDurationRuntimeInformation) : null
    const irrigationFunction = useSelector((state) => selectBladeUniqueConfigurationByName(state, bladeId, "PrimaryIrrigationFunction"))
    let irrigationTitle = "Irrigation"
    let irrigationFunctionLabel = "Irrigation Test"
    let desiredMinPressure = 0
    let desiredMaxPressure = 150
    let minPressure = 0
    let maxPressure = 200
    
    if (irrigationFunction === "aero")  {
      irrigationTitle = "Nozzles"
      irrigationFunctionLabel = "Nozzle Test"
      desiredMinPressure = 90
      desiredMaxPressure = 110
      maxPressure = 150
    }else if (irrigationFunction === "drip")  {
      irrigationTitle = "Drip Irrigation"
      irrigationFunctionLabel = "Drip Emmiter Test"
      desiredMinPressure = 30
      desiredMaxPressure = 60
      maxPressure = 150
    }


    let additionalFunctions = [
      {
        key: "run_irrig_test", label: irrigationFunctionLabel, callback: () => {
          const updatedProperties = {
            "primary_system_irrigation_test_duration": 60,
            "primary_system_requires_irrigation_test": 1,
          }
          dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: updatedProperties}))
        }
      },
    ]

    const addTimeToIrrigationTest = () => {
      const updatedProperties = {
        "primary_system_irrigation_test_duration": irrigationTestDurationRuntimeInformation ? parseFloat(irrigationTestDurationRuntimeInformation) + 60 : 60,
      }
      dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: updatedProperties}))
    }
    
    return (<>
      <div className="DataStatus-Cell">
        <div className="DataStatus-Cell-Header-Container">
          <div className="DataStatus-Cell-Header-PrimaryContent">
            <div className="Text-H14">{irrigationTitle}</div>
          </div>
          <div className="DataStatus-Cell-Header-AuxContent">
            <DropDownButton content={<AdditionalOptions/>} options={additionalFunctions} status={"Neutral"}/>
          </div>
        </div>
        <div className="DataStatus-Cell-Content-Container">
          <div className="FlexContent-15 FlexContent-HFlex">
            <AnalogGauge value={liquidPressure.value} min={minPressure} max={maxPressure} desiredMin={desiredMinPressure} desiredMax={desiredMaxPressure} suffix={"psi"}/>
            {liquidStatus === "irrigation_test" && <>
              <div className="FlexContent-H-20 FlexContent-Center">
                {liquidSubstatusRuntimeInformation !== "pending_finish" && <>
                  <div className="Text-S14">Preparing</div>
                </>}
                {liquidSubstatusRuntimeInformation === "pending_finish" && <>
                  <div className="FlexContent-H-5 Text-S14">Time Remaining: <Countdown fromTime={irrigationTestFromTime}/></div>
                  <Button status={"Primary-Inverted"} size={"Small"} content={"Add Time"} onClick={addTimeToIrrigationTest}/>
                  </>}
              </div>              
              <Button status={"Critical"} size={"Small"} content={"Stop"} onClick={() => {
                dispatch(setBladeRuntimeProperty({ bladeId: bladeId, properties: { "primary_system_requires_irrigation_test": 0 } }))
              }}/>
            </>}
          </div>
        </div>
      </div>
    </>)
  }
  
  export default BladeInstance_StatusContent_IrrigationCell