import './HarvestTask.scss';
import React from 'react';
import _ from 'underscore';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { getBladeByUID, markGrowZoneForCleanse, selectAllBlades, selectAllBladeZones, selectBladeIdsForBladeZoneUID, selectBladeZoneTypeByUID, setBladeRuntimeProperty, stopBladeZoneActiveRecipe } from '../../../../redux/entities/service/Blade';
import { markTaskAsCompleted } from '../../../../redux/entities/Tasks';
import { SoftWarning } from '../../../../assets/icons/Icons';
import { markGrowZoneEntryAsHarvested } from '../../../../redux/entities/Grow';


const HarvestTask_ZoneCleanOptionsWalkthroughStep = ({
        task,
        activeGrow,
        harvestingZoneUID,
        containers,
        cancelTaskCallback,
        taskCompletedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const zoneType = useSelector((state) => selectBladeZoneTypeByUID(state, harvestingZoneUID), _.isEqual)
    const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, harvestingZoneUID), _.isEqual)
    const waterReservoirBladeId = (zoneType === "nursery" ? bladeIds["nursery"] : bladeIds["grow"])


    const backClicked = () => {
        transitionToPreviousStep(["review_containers", null])
    }
    const dispatch = useDispatch()




    const [cleaningOption, SetCleaningOption] = React.useState(null)
    


    const [pendingCompleteStep, SetPendingCompleteStep] = React.useState(0)
    const completeClicked = () => {
        SetPendingCompleteStep(1)
    }

    React.useEffect(() => {
        console.log("Pending complete step: ", pendingCompleteStep)
        //Stop recipe
        if (pendingCompleteStep === 1)  {
            console.log("Stopping active recipe")
            dispatch(stopBladeZoneActiveRecipe({
                zoneUID: harvestingZoneUID, callback: (success) => {
                    console.log("Stopping active recipe - ", success)
                    if (success) {
                    
                    }
                    SetPendingCompleteStep(2)
                }
            }))

        //Mark grow zone entry as harvested
        }else if (pendingCompleteStep === 2)    {
            console.log("Marking Grow entry as harvested")
            dispatch(markGrowZoneEntryAsHarvested({growId: activeGrow.id, zoneUID: harvestingZoneUID, wasPartialHarvest: false, callback: (success, taskId) => {
                console.log("Marking Grow entry as harvested - ", success)
                if (success)  {
                    
                    if (cleaningOption === "clean") {
                        SetPendingCompleteStep(3)
                    }else {
                        SetPendingCompleteStep(4)
                    }
                }
            }}))
        
        //Mark zone for cleanse if designated
        }else if (pendingCompleteStep === 3)    {
            console.log("Marking for cleanse")
            dispatch(markGrowZoneForCleanse({bladeId: waterReservoirBladeId, zoneUID: harvestingZoneUID, forReservoir: "primary", callback: (success, taskId) => {
                console.log("Marking for cleanse - ", success)
                if (success)  {
                    SetPendingCompleteStep(4)
                }
            }}))
            
            
        
        //Unpause the water for the zone
        }else if (pendingCompleteStep === 4)    {
            console.log("Resuming primary liquid")
            dispatch(setBladeRuntimeProperty({
                bladeId: waterReservoirBladeId,
                properties: { "primary_liquid_control_status": "resume" },
                callback: (success) => {
                    console.log("Resuming primary liquid - ", success)
                    SetPendingCompleteStep(5)                  
                }
              }))

        //Return to zone listing (or potentially to "Cleanse now" screen)
        }else if (pendingCompleteStep === 5)    {
            SetPendingCompleteStep(0)
            transitionToNextStep(["zone_list", null])
        }
    }, [pendingCompleteStep, cleaningOption])


    
    
    


    const allBlades = useSelector((state) => selectAllBlades(state))
    const allBladeZones = useSelector((state) => selectAllBladeZones(state))

    const activeBladeZone = allBladeZones.find((bZ) => bZ.uid === harvestingZoneUID)
    const growBlade = allBlades.find((b) => b.uid === activeBladeZone.zone_props["grow_rack_uid"])
    React.useEffect(() => {
        if (activeBladeZone !== undefined && activeBladeZone.zone_props !== null && activeBladeZone.zone_props["grow_rack_uid"] !== undefined)  {
            if (allBlades.find((b) => b.uid === activeBladeZone.zone_props["grow_rack_uid"]) === undefined)  {
                dispatch(getBladeByUID({bladeUID: activeBladeZone.zone_props["grow_rack_uid"]}))
            }
        }
    }, [allBlades, activeBladeZone])


    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Does {activeBladeZone !== undefined && activeBladeZone.display_name} need cleaning?
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className="FlexContent-H-10 Linebreak-30 FlexContent-Center">
                    <SoftWarning fill={"#EC3C3C"}/>
                    <div className="Text-Medium-S16 Text-Wrap">The zone recipe is set to terminate, and the reservoir will drain</div>
                </div>

                <div className={isMobile ? "FlexContent-10" : "FlexContent-H-10"} style={{flex:1}}>
                    <Button content={<>
                        <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                            <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                                <div className={(isMobile ? "Text-Medium-S20" : "Text-Medium-S24") + " Text-Wrap"} style={{textAlign:"left"}}>Yes, Mark zone as dirty</div>
                                <div className={(isMobile ? "Text-S14" : "Text-S18") + " Text-Wrap"} style={{textAlign:"left"}}>Cleanse tasks will be created to take care of this zone before another grow can run</div>
                            </div>
                        </div>
                    </>} status={"Primary-Toggle"} state={cleaningOption === "clean"} width={"Flex100"} onClick={() => {SetCleaningOption("clean")}}/>
                <Button content={<>
                    <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                        <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                            <div className={(isMobile ? "Text-Medium-S20" : "Text-Medium-S24") + " Text-Wrap"} style={{textAlign:"left"}}>No, Skip cleaning</div>
                            <div className={(isMobile ? "Text-S14" : "Text-S18") + " Text-Wrap"} style={{textAlign:"left"}}>The zone will be ready for another grow immediately</div>
                        </div>
                    </div>
                    </>} status={"Primary-Toggle"} state={cleaningOption === "skip"} width={"Flex100"} onClick={() => {SetCleaningOption("skip")}}/>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Complete"} onClick={completeClicked} size={"Flex50"} disabled={cleaningOption === null}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ZoneCleanOptionsWalkthroughStep