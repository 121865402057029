import './ScheduleGrowPopup.scss';

import React from 'react';
import Button from '../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { FormatDate, RoundToNearest, RoundedDateToNearestLastDay } from '../../../helpers';
import Checkbox from '../../../components/input/Checkbox';
import NumberInput from '../../../components/input/NumberInput';
import { useSelector } from 'react-redux';
import { selectAllGrowPlans } from '../../../redux/entities/GrowPlans';
import { SimpleGrowBag, StandardBerryTroughZone, StandardGrowOutBoard, StandardGrowOutZone, StandardSeedlingBoard } from '../../../assets/icons/ProductIcons';
import { selectAllRecipes } from '../../../redux/entities/Recipes';
import { selectAllRaftTypes } from '../../../redux/entities/RaftTypes';
import { GenericLeaf } from '../../../assets/icons/Icons';
import Calendar from '../../../components/input/Calendar';

  

const ScheduleGrow_SelectGrowTimingWalkthroughStep = ({
        selectedGrowPlan, 
        initialSelectedTimingType,
        initialSelectedCalendarDate,
        growTimingConfirmedCallback,
        transitionToPreviousStep, transitionToNextStep}) => {

    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop

    const allGrowPlans = useSelector((state) => selectAllGrowPlans(state))
    const activeGrowPlan = allGrowPlans.find((gP) => gP.id === selectedGrowPlan)
    const allRecipes = useSelector((state) => selectAllRecipes(state))

    let selectedRecipe = undefined
    if (activeGrowPlan !== undefined)   {
        selectedRecipe = allRecipes.find((r) => r.id === activeGrowPlan.recipe_id && r.version === activeGrowPlan.recipe_version)
    }


    const updateGrowTimingValues = () => {
        growTimingConfirmedCallback({
            timingType: timingType,
            selectedCalendarDate: calendarSelectedDate,
        })
    }
    const backClicked = () => {
        updateGrowTimingValues()
        transitionToPreviousStep(["select_grow_info", null])
    }
    const nextClicked = () =>   {
        updateGrowTimingValues()
        transitionToNextStep(["review_grow", null])
    }

    const [timingType, SetTimingType] = React.useState(null)
    React.useLayoutEffect(() => {
        SetTimingType(initialSelectedTimingType)
    }, [initialSelectedTimingType])
    const timingTypeSelected = (type) => {
        SetTimingType(type)
        if (type === "next_available")  {
            SetCalendarSelectedDate(null)
        }else if (calendarSelectedDate === null)   {
            SetCalendarSelectedDate(RoundedDateToNearestLastDay(1, new Date()))
        }
    }



    const [calendarSelectedDate, SetCalendarSelectedDate] = React.useState(null)
    React.useLayoutEffect(() => {
        if (initialSelectedTimingType !== "next_available")    {
            if (initialSelectedCalendarDate === null && selectedRecipe !== undefined)  {
                SetCalendarSelectedDate(RoundedDateToNearestLastDay(1, new Date()))
            }else {
                SetCalendarSelectedDate(RoundedDateToNearestLastDay(1, initialSelectedCalendarDate))
            }
        }else {
            SetCalendarSelectedDate(null)
        }
    }, [initialSelectedTimingType, initialSelectedCalendarDate, selectedRecipe])
    const onCalendarDayClicked = (date) =>   {
        if (timingType === "start_date")    {
            SetCalendarSelectedDate(date.from)
        }else if (timingType === "harvest_on")    {
            SetCalendarSelectedDate(date.to)
        }else {
            SetCalendarSelectedDate(null)
        }
    }


    React.useEffect(() => {
        updateGrowTimingValues()
    }, [timingType, calendarSelectedDate])



    let currentDateRange = null
    if (calendarSelectedDate !== null && selectedRecipe !== undefined)  {
        if (timingType === "start_date")    {
            let currentDay = RoundedDateToNearestLastDay(1, calendarSelectedDate)
            let finalDay = new Date(currentDay.getTime() + selectedRecipe.expected_duration * 1000)
            currentDateRange = {from: currentDay, to: finalDay}
        }else if (timingType === "harvest_on")  {
            let currentDay = RoundedDateToNearestLastDay(1, calendarSelectedDate)
            let firstDay = new Date(currentDay.getTime() - selectedRecipe.expected_duration * 1000)
            currentDateRange = {from: firstDay, to: currentDay}
        }
    }

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper Walkthrough-Card-Content-Center"}>
                <div className={"Walkthrough-Card-Content" + (!isMobile ? " Walkthrough-Card-Content-Split" : "")}>
                    <div className="FlexContent-20 FlexContent-Center">
                        <div className="FlexContent-20">
                            <div className="FlexContent-H-5" onClick={() => {timingTypeSelected("start_date")}}>
                                <Checkbox style={"radio"} state={timingType === "start_date"} canToggleOff={false} onChange={() => {timingTypeSelected("start_date")}}/>
                                <div className="noselect">Select Start Date</div>
                            </div>
                            <div className="FlexContent-H-5" onClick={() => {timingTypeSelected("harvest_on")}}>
                                <Checkbox style={"radio"} state={timingType === "harvest_on"} canToggleOff={false} onChange={() => {timingTypeSelected("harvest_on")}}/>
                                <div className="noselect">Select Harvest Date</div>
                            </div>
                            {/*<div className="FlexContent-H-5" onClick={() => {timingTypeSelected("next_available")}}>
                                <Checkbox style={"radio"} state={timingType === "next_available"} canToggleOff={false} onChange={() => {timingTypeSelected("next_available")}}/>
                                <div className="noselect">Next Available Slot</div>
                            </div>*/}
                        </div>
                        <div className="FlexContent-10">
                            <div className="FlexContent-H-10">
                                <div>Start Date</div>
                                <div>
                                    {(currentDateRange === null && timingType === "next_available") && 
                                        <>Next Available</>
                                    }
                                    {currentDateRange && <>
                                        {FormatDate(currentDateRange["start"], "MMMM dth yyyy")}
                                    </>}
                                </div>
                            </div>
                            <div className="FlexContent-H-10">
                                <div>Harvest Date</div>
                                <div>
                                    {currentDateRange && <>
                                        {FormatDate(currentDateRange["end"], "MMMM dth yyyy")}
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>


                    <Calendar
                        isRange={true}
                        selectedDateRange={currentDateRange}
                        multiMonth={!isMobile}
                        isStartAndEndSelectable={false}
                        selectingDateState={timingType === "harvest_on" ? "end" : "start"}
                        selectedDateRangeChanged={onCalendarDayClicked}/>

                    {/*<Calendar
                        value={calendarSelectedDate}
                        dateClicked={onCalendarDayClicked}
                        disabled={timingType === "next_available"}
                        tileContent={(date) => {
                            let tileClassName = "ScheduleGrowPopup-Calendar-DayTileContent"
                            let additionalContent = null
                            if (currentDateRange !== null)  {
                                if (date.getTime() === currentDateRange.start.getTime()) {
                                    tileClassName += " ScheduleGrowPopup-Calendar-SelectedDayStart"
                                    if (calendarSelectedDate && calendarSelectedDate.getTime() === currentDateRange.start.getTime())   {
                                        additionalContent = <div className="ScheduleGrowPopup-Calendar-SelectedDayStart-Visual"></div>
                                    }else {
                                        additionalContent = <div className="ScheduleGrowPopup-Calendar-SelectedDayHarvest-Visual"></div>
                                    }
                                }else if (date.getTime() === currentDateRange.end.getTime()) {
                                    tileClassName += " ScheduleGrowPopup-Calendar-SelectedDayHarvest"
                                    if (calendarSelectedDate && calendarSelectedDate.getTime() === currentDateRange.end.getTime())   {
                                        additionalContent = <div className="ScheduleGrowPopup-Calendar-SelectedDayStart-Visual"></div>
                                    }else {
                                        additionalContent = <div className="ScheduleGrowPopup-Calendar-SelectedDayHarvest-Visual"></div>
                                    }
                                }else if (date.getTime() >= currentDateRange.start.getTime() && date.getTime() <= currentDateRange.end.getTime()) {
                                    tileClassName += " ScheduleGrowPopup-Calendar-SelectedDayRange"
                                }
                                if (calendarSelectedDate && date.getTime() === calendarSelectedDate.getTime())  {
                                    tileClassName += " ScheduleGrowPopup-Calendar-SelectedDay"
                                }
                            }

                            return (
                                <div className="ScheduleGrowPopup-Calendar-DayTile">
                                    <div className={tileClassName}>
                                        {date.getDate()}
                                        {additionalContent && additionalContent}
                                    </div>
                                </div>
                            )
                        }}/>*/}
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Continue"} onClick={nextClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
            </div>
        </div>
    )
    
}


export default ScheduleGrow_SelectGrowTimingWalkthroughStep