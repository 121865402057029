import './NumberInput.scss';
import React from 'react'

import { RoundToNearest, useMeasure } from '../../helpers'
import {FiPlus, FiMinus} from 'react-icons/fi'

const NumberInput = ({value, stepper, stepAmount, stepResolution, prefix, suffix, type, fontSize, onChange, onBlur, flex, size, maxLength, displayResolution, precision, min, max, alignNumber, disabled, showDisabledVisual, canEdit, checkValidStatus}) => {
  const [focusState, SetFocusState] = React.useState(false);
  const containerRef = React.useRef(null);

  if (type === "hours") {
    suffix = "h"
    stepResolution = 3600
    size = 2
  }else if (type === "minutes") {
    suffix = "m"
    stepResolution = 15 * 60
    size = 2
  }

  if (!canEdit) {
    stepper = false
  }


  React.useEffect(() => {
    if (!containerRef.current)
      return

    
  }, [containerRef, focusState])

  const [numberValue, setNumberValue] = React.useState(value);
  const [tempValue, setTempValue] = React.useState(null)

  const numberValueRef = React.useRef(null);
  const numberInput = React.useRef(null);

  const [inTempEmptyMode, SetInTempEmptyMode] = React.useState(false)


  const calculateValueDisplay = (value) =>  {
    if (inTempEmptyMode)  {
      return ""
    }
    let valueDisplay = value
    if (type === "default") {
      valueDisplay = value
    }else if (type === "hours") {
      valueDisplay = Math.floor(value / 3600)
    }else if (type === "minutes") {
      valueDisplay = Math.floor((value % 3600) / 60)
    }

    if (precision)  {
      valueDisplay = Math.round(value * (1 / precision)) / (1 / precision)
    }
    if (displayResolution)  {
      valueDisplay = RoundToNearest(value, displayResolution)
    }
    return valueDisplay
  }
  const getValueToDisplay = () => {
    return (tempValue !== null) ? calculateValueDisplay(tempValue) : calculateValueDisplay(numberValue)
  }
  const getInputLength = () => {
    if (numberInput.current)  {
      return numberInput.current.value.length
    }
    return getValueToDisplay().length
  }



  const buttonUpClicked = React.useCallback((e) => {
    if (disabled || !canEdit)
      return
    SetInTempEmptyMode(false)
    let resultantValue = parseFloat(numberValue)
    if (type === "default") {
      resultantValue = numberValue + stepAmount
    }else if (type === "hours") {
      resultantValue = numberValue + (stepAmount * 3600)
    }else if (type === "minutes") {
      resultantValue = numberValue + (stepAmount * 60)
    }
    console.log(numberValue, parseFloat(numberValue), resultantValue, stepAmount)
    if (stepResolution) {
      resultantValue = RoundToNearest(resultantValue, stepResolution, "round")
    }
    const finalValue = processValue(resultantValue)
    numberInput.current.value = finalValue
  })

  const buttonDownClicked = React.useCallback((e) => {
    if (disabled || !canEdit)
      return
    SetInTempEmptyMode(false)
    let resultantValue = parseFloat(numberValue)
    if (type === "default") {
      resultantValue = numberValue - stepAmount
    }else if (type === "hours") {
      resultantValue = numberValue - (stepAmount * 3600)
    }else if (type === "minutes") {
      resultantValue = numberValue - (stepAmount * 60)
    }
    if (stepResolution) {
      resultantValue = RoundToNearest(resultantValue, stepResolution, "round")
    }
    const finalValue = processValue(resultantValue)
    numberInput.current.value = finalValue
  })

  const valueChanged = React.useCallback((e) =>  {
    if (disabled || !canEdit)
      return
    
    e.preventDefault()
    if (e.target.value === "")  {
      setTempValue(e.target.value)
      SetLastValue(e.target.value)

    }else if (isNaN(e.target.value) || isNaN(parseFloat(e.target.value))) {
      numberInput.current.value = lastValue
    }else {
      setTempValue(e.target.value)
      SetLastValue(e.target.value)
    }
    
    //processValue(e.target.value)
  })

  const preprocessValue = (value) => {
    if (type === "default") {
      return value
    }else if (type === "hours") {
      let existingMinutes = Math.floor((numberValue % 3600) / 60) * 60
      if (value === "" || isNaN(value) || isNaN(parseFloat(value))) {
        return existingMinutes
      }
      return (parseFloat(value) * 3600) + existingMinutes
    }else if (type === "minutes") {
      let existingHours = Math.floor(numberValue / 3600) * 3600
      if (value === "" || isNaN(value) || isNaN(parseFloat(value))) {
        return existingHours
      }
      return existingHours + (parseFloat(value) * 60)
    }
  }

  const processValue = (newValue) => {
    if (newValue === "")  {
      newValue = 0
    }
    let requestedValue = parseFloat(newValue)
    if (isNaN(requestedValue)) {
      requestedValue = 0
    }
    if (requestedValue == numberValue)  {
      numberInput.current.value = calculateValueDisplay(requestedValue)
      return requestedValue
    }
    let valid = true
    if (min !== null && requestedValue < min)  {
      requestedValue = min
      valid = false
    }
    if (max !== null && requestedValue > max)  {
      requestedValue = max
      valid = false
    }
      
    if (onChange !== undefined) {
      let result = onChange(requestedValue)
      if (result !== undefined) {
        //numberInput.current.value = result
        setNumberValue(result)
        return result
      }else {
        numberInput.current.value = calculateValueDisplay(requestedValue)
        setNumberValue(requestedValue)
        return requestedValue
      }
    }else {
      if (!valid) {
        numberInput.current.value = calculateValueDisplay(requestedValue)
        setNumberValue(requestedValue)
        return requestedValue
      }
    }

    setNumberValue(parseFloat(newValue))
    return parseFloat(newValue)
  }


  React.useEffect(() => {
    setNumberValue(value)
  }, [value])

  React.useEffect(() => {
    numberValueRef.current = numberValue
  }, [numberValue])


  const handleFocus = React.useCallback((e) => {
    if (disabled || !canEdit) {
      SetFocusState(false)
      return
    }
    if (!focusState)  {
      SetFocusState(true)
    }
  })


  const [lastValue, SetLastValue] = React.useState(null)
  
  React.useLayoutEffect(() => {
    SetLastValue(getValueToDisplay())
  }, [numberValue, tempValue])
  
  const onKeyUp = (e) => {
    if (focusState && (e.key === "Enter" || e.keyCode === 13)) {
      blur()
    }
  }
  
  const blurDetectPointerDown = (e) => {
    if (focusState && containerRef.current) {
      blur()
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }

  const blur = React.useCallback(() => {
    SetFocusState(false)
    SetInTempEmptyMode(false)
    if (numberInput.current) {
      numberInput.current.blur()
    }
    if (tempValue !== null)  {
      const preprocessedvalue = preprocessValue(tempValue)
      const processedValue = processValue(preprocessedvalue)
      setTempValue(null)
      if (onBlur !== undefined) {      
        onBlur(processedValue)
      }
    }else if (onBlur !== undefined) {      
      onBlur(numberValueRef.current)
    }
  })


  
  React.useEffect(() => {
    if (focusState) {
      return
    }
    numberInput.current.value = (tempValue !== null) ? calculateValueDisplay(tempValue) : calculateValueDisplay(numberValue)
  }, [numberValue, tempValue, inTempEmptyMode, focusState])

  //let defaultValue = calculateValueDisplay(numberValue)

  const inputHitboxClicked = React.useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
    handleFocus(e)
    numberInput.current.focus()
  })


  const valueToDisplay = getValueToDisplay()

  let inputProps = {}
  if (maxLength)  {
    inputProps["maxLength"] = maxLength
  }
  inputProps["placeholder"] = min !== null ? min.toString() : "0"

  let valueInputLength = null
  const containerProps = {style:{}}
  if (flex === true) {
    containerProps.style.flex = "1"
    containerProps.style.alignSelf = "stretch"
    size = null
    valueInputLength = getInputLength()
  }else {
    valueInputLength = getInputLength()
    if (valueInputLength <= 1) {
      valueInputLength = 1
    }
    if (valueInputLength < size) {
      valueInputLength = size
    }else if (valueInputLength > size)  {
      size = valueInputLength
    }
  }

  const isValid = checkValidStatus ? checkValidStatus(valueToDisplay) : true


  return (<>
    <div className={"NumberInput" + (focusState ? " NumberInput-Focused" : "") + ((disabled && showDisabledVisual) ? " NumberInput-Disabled" : "") + (!isValid ? " NumberInput-Invalid" : "")} onClick={handleFocus} ref={containerRef} {...containerProps}>
      {stepper && 
        <div 
          className="NumberInput_ButtonUp noselect" 
          /*style={{width: inputHeight * widthRatio, height: inputHeight / 2}}   style={{width: inputHeight * widthRatio}}*/
          onClick={buttonDownClicked}>
          <div className="NumberInput_Button noselect">
            <FiMinus/>
          </div>
        </div>
      }
      <div className="NumberInput_ContentWrapper" onClick={inputHitboxClicked}>
        <div className="NumberInput_Content"
          style={{ width: size ? (size + (suffix ? suffix.length : 0)) + 'ch' : 'auto', fontSize: fontSize}}>
          {prefix &&
            <div className="NumberInput_Prefix noselect">
              {prefix}
            </div>
          }
          <div className="NumberInput_DisplayArea">
            <input
              defaultValue={valueToDisplay}
              style={{ width: valueInputLength ? valueInputLength + 'ch' : 'auto', fontSize: fontSize, textAlign: alignNumber}}
              onChange={valueChanged} 
              type="text" pattern="[0-9]*"
              ref={numberInput}
              disabled={disabled || !canEdit}
              onKeyUp={onKeyUp}
              onClick={(e) => {
                e.stopPropagation()
                handleFocus(e)
              }}
              {...inputProps}/>
          </div>
          {suffix &&
            <div className="NumberInput_Suffix noselect">
              {suffix}
            </div>
          }
        </div>
      </div>

    {stepper && 
        <div 
          className="NumberInput_ButtonDown noselect" 
          /*style={{width: inputHeight * widthRatio, height: inputHeight / 2}}*/
          onClick={buttonUpClicked}>
          <div className="NumberInput_Button noselect">
            <FiPlus/>
          </div>
        </div>
      }
    </div>
    <div className={"NumberInput-BlurDetect" + (focusState ? " NumberInput-BlurDetect-Active" : "")}
    onPointerDown={blurDetectPointerDown}></div>
    </>
  )
} 

NumberInput.defaultProps = {
  value: "",
  stepper: true,
  stepAmount: 1,
  stepResolution: null,
  prefix: null,
  suffix: null,
  type: "default",
  alignNumber: "right",
  fontSize: 16,
  flex: null,
  size: 4,
  maxLength: null,
  precision: null,
  displayResolution: null,
  min: null,
  max: null,
  disabled: false,
  showDisabledVisual: true,
  canEdit: true,
  checkValidStatus: null
}


export default NumberInput