import './GrowZonesPage.scss';
import variables from '../../globals.scss';
import React from 'react'

import _ from 'underscore';
import { useMediaQuery } from 'react-responsive';
import GrowZone_StatusContent_CanopyAirCell from './GrowZone_StatusContent_Cells/GrowZone_StatusContent_CanopyAirCell';
import GrowZone_StatusContent_LightsCell from './GrowZone_StatusContent_Cells/GrowZone_StatusContent_LightsCell';
import { MaintainBladeZoneActiveRecipe, selectBladeZoneActiveGrowIds, selectBladeZoneTypeByUID } from '../../redux/entities/service/Blade';
import { useSelector } from 'react-redux';
import GrowZone_StatusContent_PrimaryWaterCell from './GrowZone_StatusContent_Cells/GrowZone_StatusContent_PrimaryWaterCell';
import GrowZone_StatusContent_NurseryUpperWaterCell from './GrowZone_StatusContent_Cells/GrowZone_StatusContent_NurseryUpperWaterCell';
import GrowZone_StatusContent_NurseryLowerWaterCell from './GrowZone_StatusContent_Cells/GrowZone_StatusContent_NurseryLowerWaterCell';
import GrowZone_StatusContent_EnergyCell from './GrowZone_StatusContent_Cells/GrowZone_StatusContent_EnergyCell';
import GrowZone_StatusContent_CondensateCell from './GrowZone_StatusContent_Cells/GrowZone_StatusContent_CondensateCell';
import GrowStatusList_Instance from '../GrowManager/GrowStatusList_Instance';
import { MaintainGrowLiveData, selectAllGrowPlanIdsForGrows, selectAllRecipeInfoForGrows } from '../../redux/entities/Grow';
import { MaintainRecipeVersions } from '../../redux/entities/Recipes';
import { InitialLoadAllGrowPlans } from '../../redux/entities/GrowPlans';






const GrowZone_StatusContent = ({ zoneUID, onViewingTask }) => {

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const zoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const activeBladeZoneGrowIds = useSelector((state) => selectBladeZoneActiveGrowIds(state, zoneUID), _.isEqual)
  const [activeBladeZoneGrowIndex, SetActiveBladeZoneGrowIndex] = React.useState(0)
  const activeRecipesInfo = useSelector((state) => selectAllRecipeInfoForGrows(state, activeBladeZoneGrowIds), _.isEqual)
  const activeGrowPlanIds = useSelector((state) => selectAllGrowPlanIdsForGrows(state, activeBladeZoneGrowIds), _.isEqual)
  
  let tileColumns = []
  if (isWideDesktop) {
    tileColumns.push(["canopy_air", "lights"])
    tileColumns.push(["energy", "condensate"])
    if (zoneType === "nursery") {
      tileColumns.push(["nursery_upper_water", "nursery_lower_water"])
    }else {
      tileColumns.push(["primary_water"])
    }
  } else if (isDesktop) {
    tileColumns.push(["canopy_air", "lights"])
    tileColumns.push(["energy", "condensate"])
    if (zoneType === "nursery") {
      tileColumns.push(["nursery_upper_water", "nursery_lower_water"])
    }else {
      tileColumns.push(["primary_water"])
    }
  } else if (isTablet) {
    tileColumns.push(["canopy_air", "lights", "energy", "condensate"])
    if (zoneType === "nursery") {
      tileColumns.push(["nursery_upper_water", "nursery_lower_water"])
    }else {
      tileColumns.push(["primary_water"])
    }

  } else {
    let firstColumnCells = ["canopy_air", "lights"]
    if (zoneType === "nursery") {
      firstColumnCells.push(...["nursery_upper_water", "nursery_lower_water"])
    }else {
      firstColumnCells.push(...["primary_water"])
    }
    firstColumnCells.push(...["energy", "condensate"])

    tileColumns.push(firstColumnCells)
  }



  return (<>
    <MaintainRecipeVersions activeRecipes={activeRecipesInfo}/>
    <InitialLoadAllGrowPlans growPlanIds={activeGrowPlanIds}/>
    <MaintainGrowLiveData growIds={activeBladeZoneGrowIds}/>


    {activeBladeZoneGrowIds.length > 0 && <>
      <div className="BladeZoneInstance-ActiveGrows-Container">
        {activeBladeZoneGrowIds.length > 1 && <>
          <div>
          
          </div>  
        </>}
        <div className="BladeZoneInstance-ActiveGrows">
          <GrowStatusList_Instance growId={activeBladeZoneGrowIds[activeBladeZoneGrowIndex]} columnSizes={[]} showOnlyCard={true} onViewingTask={onViewingTask}/>
        </div>
        {activeBladeZoneGrowIds.length > 1 && <>        
          <div>
            
          </div>
        </>}
      </div>    
    </>}
    <div className="BladeInstance_StatusContent">
      {tileColumns.map((cellKeys) => {

        return (<>
          <div className="BladeInstance_StatusContent-Column">
            {cellKeys.map((cellKey) => {
              switch (cellKey) {
                case "canopy_air":
                  return <GrowZone_StatusContent_CanopyAirCell zoneUID={zoneUID} />
                
                case "lights":
                  return <GrowZone_StatusContent_LightsCell zoneUID={zoneUID} />

                case "primary_water":
                  return <GrowZone_StatusContent_PrimaryWaterCell zoneUID={zoneUID} />

                case "nursery_upper_water":
                  return <GrowZone_StatusContent_NurseryUpperWaterCell zoneUID={zoneUID} />

                case "nursery_lower_water":
                  return <GrowZone_StatusContent_NurseryLowerWaterCell zoneUID={zoneUID} />
    
                case "energy":
                  return <GrowZone_StatusContent_EnergyCell zoneUID={zoneUID} />
  
                case "condensate":
                  return <GrowZone_StatusContent_CondensateCell zoneUID={zoneUID} />

                default:
                  break
              }
            })}
          </div>
        </>)
      })}
    </div>
  </>)
}

export default GrowZone_StatusContent