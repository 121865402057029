import './Calendar.scss';
import React from 'react'

import { daysInMonth, FormatDate } from '../../helpers';
import useMeasure from '../../useMeasure';
import { LeftArrow, RightArrow } from '../../assets/icons/Icons';
import Button from '../Button';


const Calendar = ({
    selectedDate = null, selectedDateChanged, 
    isRange, selectedDateRange, selectedDateRangeChanged,
    selectingDateState = "start", isStartAndEndSelectable = true,
    dateClicked, multiMonth, processDateContent,
    flex = true, disabled = false,
  }) => {
 

  const [activeMonth, SetActiveMonth] = React.useState(new Date())
  const [initialActiveMonthSet, SetInitialActiveMonthSet] = React.useState(false)
  React.useLayoutEffect(() => {
    if (!initialActiveMonthSet) {
      if (isRange)  {
        if (selectedDateRange !== null) {
          SetActiveMonth(selectedDateRange.from.setToFirstDayOfMonth())
          SetInitialActiveMonthSet(true)
        }
      }else {
        if (selectedDate !== null)  {
          SetActiveMonth(selectedDate.setToFirstDayOfMonth())
          SetInitialActiveMonthSet(true)
        }
      }
    }
  }, [initialActiveMonthSet, selectedDateRange, selectedDate])
  const switchActiveMonth = (monthDate) => {
    SetActiveMonth(monthDate.setToFirstDayOfMonth())
  }

  const [currentSelectingDateState, SetSelectingDateState] = React.useState("start")
  React.useLayoutEffect(() => {
    if (selectingDateState !== null)  {
      SetSelectingDateState(selectingDateState)
    }
  }, [selectingDateState])
  const selectDate = (date) => {
    if (!isRange)  {
      if (selectedDateChanged)  {
        selectedDateChanged(date)
      }
      return
    }

    let newFromDate = selectedDateRange.from
    let newToDate = selectedDateRange.to

    if (currentSelectingDateState === "start") {
      newFromDate = date
      newFromDate.setHours(0,0,0,0)
      if (newFromDate.getTime() > newToDate.getTime()) {
        newToDate = new Date(newFromDate)
        newToDate.setHours(23,59,59,999)
      }
      
      if (isStartAndEndSelectable)  {
        SetSelectingDateState("end")
      }
    }else if (currentSelectingDateState === "end") {
      
      newToDate = date
      newToDate.setHours(23,59,59,999)
      if (newToDate.getTime() < newFromDate.getTime())  {
        newFromDate = new Date(newToDate)
        newFromDate.setHours(0,0,0,0)
      }
      if (isStartAndEndSelectable)  {
        SetSelectingDateState("start")
      }
    }

    if (selectedDateRangeChanged !== undefined) {
      selectedDateRangeChanged({from: newFromDate, to: newToDate})
    }

  }
  
  if (multiMonth) {
    const nextMonth = activeMonth.addMonths(1)
    return (<>
        <div className={"FlexContent-H-10" + (flex ? " FlexContent-HFlex" : "")}>
          <CalendarMonth 
            monthDate={activeMonth}
            selectedDate={selectedDate}
            isRange={isRange}
            selectedDateRange={selectedDateRange}
            selectDateCallback={selectDate}
            showPreviousMonthDays={true}
            showNextMonthDays={false}
            switchMonthCallback={switchActiveMonth}
            flex={flex}
          />
          <CalendarMonth 
            monthDate={nextMonth}
            selectedDate={selectedDate}
            isRange={isRange}
            selectedDateRange={selectedDateRange}
            selectDateCallback={selectDate}
            showPreviousMonthDays={false}
            showNextMonthDays={true}
            switchMonthCallback={switchActiveMonth}
            flex={flex}
          />
        </div>
    </>)
  }else {
    return (<>
      <CalendarMonth 
        monthDate={activeMonth}
        selectedDate={selectedDate}
        isRange={isRange}
        selectedDateRange={selectedDateRange}
        selectDateCallback={selectDate}
        switchMonthCallback={switchActiveMonth}
        flex={flex}/>  
    </>)    
  }
}


const CalendarMonth = ({
    monthDate,
    selectedDate,
    isRange = false,
    selectedDateRange,
    selectDateCallback,
    showPreviousMonthDays = true,
    showNextMonthDays = true,
    flex,
    switchMonthCallback,
  }) => {
  
  const numberOfColumns = 7
  const numberOfRows = 6
  const aspectRatio = 1.0

  const [calendarContentRef, {width: calendarWidth}] = useMeasure()
  const calendarCellWidth = calendarWidth / numberOfColumns
  const calendarCellHeight = calendarCellWidth / aspectRatio



  const [calculatedDates, SetCalculatedDates] = React.useState({})
  React.useLayoutEffect(() => {
    //Based on the month date, determine the days to show for all 42 slots.
    let currentCalculatedDates = {}

    const monthStartedOnDayOfTheWeek = monthDate.getDay()

    if (showPreviousMonthDays)  {
      for (let i = 0; i < monthStartedOnDayOfTheWeek; i++) {
        let currentDate = new Date(monthDate).addDays(-(monthStartedOnDayOfTheWeek - i))

        currentCalculatedDates[i] = {
          date: currentDate
        }
      }
    }
    for (let i = monthStartedOnDayOfTheWeek; i < numberOfRows * numberOfColumns; i++)  {
      let currentDate = new Date(monthDate).addDays(i - monthStartedOnDayOfTheWeek)
      if (showNextMonthDays || currentDate.getMonth() === monthDate.getMonth()) {
        currentCalculatedDates[i] = {
          date: currentDate
        }
      }
    }


    SetCalculatedDates(currentCalculatedDates)
  }, [monthDate])



  const switchViewingMonthBackClicked = () => {
    switchMonthCallback(monthDate.addMonths(-1))
  }
  const switchViewingMonthForwardClicked = () => {
    switchMonthCallback(monthDate.addMonths(1))
  }

  const containerProps = {style:{}}
  if (flex) {
    containerProps.style.flex = 1
  }

  return (<>
    <div className="CalendarMonth-Container" {...containerProps}>
      <div className="CalendarMonth">
        <div className="CalendarMonth-Content" ref={calendarContentRef}>
          <div className="CalendarMonth-Header">
            <Button status={"Icon-Action"} content={<LeftArrow fill={"#1D65F0"}/>} onClick={switchViewingMonthBackClicked}/>
            <div className="Text-Medium-S16">{FormatDate(monthDate, "MMM yyyy")}</div>
            <Button status={"Icon-Action"} content={<RightArrow fill={"#1D65F0"}/>} onClick={switchViewingMonthForwardClicked}/>
          </div>
          <div className="CalendarMonth-StandardRows">
            {Array(numberOfRows).fill().map(function (_, row) {
              const rowProps = {style:{}}
              return (
                <div key={row} className="CalendarMonth-StandardRow" {...rowProps}>
                  {Array(numberOfColumns).fill().map(function (_, column) {
                    const cellProps = {style:{
                      height: calendarCellHeight,
                      width: (100 / 7) + "%"
                    }}

                    let calculatedDate = calculatedDates[(row * numberOfColumns) + column]
                    if (calculatedDate !== undefined) {
                      const isSameMonthDate = calculatedDate.date.getMonth() === monthDate.getMonth()
                      let isSelectedStartDate, isWithinSelectedDateRange, isSelectedEndDate = false 
                      if (!isRange)  {
                        if (selectedDate !== null)  {
                          isSelectedStartDate = (calculatedDate.date.getFullYear() === selectedDate.getFullYear() && calculatedDate.date.getMonth() === selectedDate.getMonth() && calculatedDate.date.getDate() === selectedDate.getDate())
                        }
                      }else {
                        isSelectedStartDate = (calculatedDate.date.getFullYear() === selectedDateRange.from.getFullYear() && calculatedDate.date.getMonth() === selectedDateRange.from.getMonth() && calculatedDate.date.getDate() === selectedDateRange.from.getDate())
                        isSelectedEndDate = (calculatedDate.date.getFullYear() === selectedDateRange.to.getFullYear() && calculatedDate.date.getMonth() === selectedDateRange.to.getMonth() && calculatedDate.date.getDate() === selectedDateRange.to.getDate())
                        if (!isSelectedEndDate) {
                          isWithinSelectedDateRange = calculatedDate.date.getTime() > selectedDateRange.from.getTime() && calculatedDate.date.getTime() < selectedDateRange.to.getTime()
                        }
                      }
                      return (<>
                        <div key={column} 
                          className={"CalendarMonth-StandardCell" + (isSameMonthDate ? " CalendarMonth-StandardMonthCell" : " CalendarMonth-StandardOtherMonthCell")} 
                          onClick={() => {selectDateCallback(calculatedDate.date)}}
                          {...cellProps}>
                          {isSelectedStartDate && <>
                            {(isRange && !isSelectedEndDate) && <>
                              <div 
                                className={"CalendarMonth-StandardCell-StartOfDateRangeVisual"}>
                              </div>
                            </>}
                            <div 
                              className={"CalendarMonth-StandardCell-StartDateVisual"}>
                            </div>
                          </>}
                          {isWithinSelectedDateRange && <>
                            {calculatedDate.date.getDay() === 0 && <>
                              <div className={"CalendarMonth-StandardCell-InDateRangeVisual-StartWeek"}></div>
                            </>}
                            {(calculatedDate.date.getDay() !== 0 && calculatedDate.date.getDay() !== 6) && <>
                              <div className={"CalendarMonth-StandardCell-InDateRangeVisual-Interweek"}></div>
                            </>}
                            {calculatedDate.date.getDay() === 6 && <>
                              <div className={"CalendarMonth-StandardCell-InDateRangeVisual-EndWeek"}></div>
                            </>}
                          
                          </>}
                          {isSelectedEndDate && <>
                            {!isSelectedStartDate && <>
                              <div 
                                className={"CalendarMonth-StandardCell-EndOfDateRangeVisual"}>
                              </div>
                            </>}
                            <div 
                              className={"CalendarMonth-StandardCell-EndDateVisual"}>
                            </div>
                          </>}
                          <div className="CalendarMonth-StandardCell-Content">
                            {FormatDate(calculatedDate.date, "d")}
                          </div>
                        </div>                      
                      </>)
                    }else {
                      return (<>
                        <div key={column} className="CalendarMonth-StandardCell" {...cellProps}></div>
                      </>)
                    }
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  </>)
}




export default Calendar