import './Blade_CleanseTask.scss';
import React from 'react';


import { useSelector, useDispatch} from 'react-redux'
import Walkthrough from '../../../../../components/Walkthrough';
import { useMediaQuery } from 'react-responsive';
import Blade_CleanseTask_SprayDownOptionsStep from './Blade_CleanseTask_SprayDownOptionsStep';
import { getBladeById, getBladeCleanseSubtask, getBladeStatusById, getBladeZoneByUID, markGrowZoneCleanseAsCompleted, selectAllBladeZones, selectAllBlades, setBladeCleanseSubtaskStage, setBladeRuntimeProperty } from '../../../../../redux/entities/service/Blade';
import Blade_CleanseTask_FilterChangeOptionsStep from './Blade_CleanseTask_FilterChangeOptionsStep';
import Blade_CleanseTask_CleaningOutStep from './Blade_CleanseTask_CleaningOutStep';
import Blade_CleanseTask_ChangeFilterStep from './Blade_CleanseTask_ChangeFilterStep';
import Blade_CleanseTask_CleanseBathOptionsStep from './Blade_CleanseTask_CleanseBathOptionsStep';
import Blade_CleanseTask_FullCleanseStep from './Blade_CleanseTask_FullCleanseStep';
import { markTaskAsCompleted } from '../../../../../redux/entities/Tasks';

const Blade_CleanseTask = ({task, onCloseTask, onClosePopup}) => {

  const navigate = useDispatch()
  const dispatch = useDispatch()

  const isWideDesktop = useMediaQuery({ minWidth: 1800 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closeTask = () =>  {
    onCloseTask()
  }
  const closePopup = () =>  {
    onClosePopup()
  }

  const taskConfirmedCompleted = () =>  {
    onCloseTask()
  }


  const [haveSubtaskInfo, SetHaveSubtaskInfo] = React.useState(false)
  const [subTaskInfo, SetSubTaskInfo] = React.useState({})

  React.useLayoutEffect(() => {
    if (!haveSubtaskInfo) {
      dispatch(getBladeCleanseSubtask({taskId: task.id, callback: (success, info) => {
        if (success)  {
          SetSubTaskInfo(info)
          SetHaveSubtaskInfo(true)
        }
      }}))
    }
  }, [haveSubtaskInfo])


  //console.log(subTaskInfo)


  let allBlades = useSelector((state) => selectAllBlades(state))
  const [activeBlade, SetActiveBlade] = React.useState(null)
  React.useEffect(() => {
    if (subTaskInfo !== null && subTaskInfo["blade_id"] !== undefined) {
      let foundBlade = allBlades.find((b) => b.id === subTaskInfo["blade_id"])
      if (foundBlade === undefined)  {
        dispatch(getBladeById({bladeId: subTaskInfo["blade_id"]}))
      }else {
        SetActiveBlade(foundBlade)
      }
    }
  }, [subTaskInfo, allBlades])


  
  const lastRackStatusUpdateOn = React.useRef(new Date().getTime())
  React.useEffect(() => {    
    const requestInterval = setInterval(() => {
      if (lastRackStatusUpdateOn.current + 2000 < new Date().getTime()) {
        dispatch(getBladeStatusById({bladeId: activeBlade.id}))
        lastRackStatusUpdateOn.current = new Date().getTime()
      }
    }, 100);
    
    return () => {
        clearInterval(requestInterval)
    };
  }, [activeBlade])



  let allBladeZones = useSelector((state) => selectAllBladeZones(state))
  const [activeBladeZone, SetActiveBladeZone] = React.useState(null)
  React.useEffect(() => {
    if (subTaskInfo !== null && subTaskInfo["zone_uid"] !== undefined) {
      let foundBladeZone = allBladeZones.find((bZ) => bZ.uid === subTaskInfo["zone_uid"])
      if (foundBladeZone === undefined)  {
        dispatch(getBladeZoneByUID({UID: subTaskInfo["zone_uid"]}))
      }else {
        SetActiveBladeZone(foundBladeZone)
      }
    }
  }, [subTaskInfo, allBladeZones])
  //console.log(activeBladeZone) 


  const [activeZoneName, SetActiveZoneName] = React.useState("Zone")
  React.useLayoutEffect(() => {
    if (activeBladeZone && subTaskInfo) {
      if (activeBladeZone.zone_type === "nursery") {
        if (subTaskInfo["for_reservoir"] === "aux_a") {
          SetActiveZoneName(activeBladeZone.display_name + " - Upper Zone")
        }else if (subTaskInfo["for_reservoir"] === "aux_b") {
          SetActiveZoneName(activeBladeZone.display_name + " - Lower Zone")
        }
      }else if (activeBladeZone.zone_type === "grow_boards" || activeBladeZone.zone_type === "berry_troughs") {
        SetActiveZoneName(activeBladeZone.display_name)
      }
    }
  }, [activeBladeZone, subTaskInfo])

  let activeStage = "unknown"
  if (subTaskInfo !== null && subTaskInfo["stage"] !== undefined) {
    activeStage = subTaskInfo["stage"]
  }

  const getActiveStage = () => {
    //console.log(activeStage)
    switch(activeStage) {
      case "pending_start":
        return ["spray_down_options", null]
      
      case "cleaning_out":
        return ["cleaning_out", null]

      case "change_filter_options":
        return ["filter_change_options", null]

      case "changing_filter":
        return ["changing_filter", null]

      case "cleanse_bath_options":
        return ["cleanse_bath_options", null]

      case "full_cleanse":
        return ["full_cleanse", null]
    }
    return null
  }




  const skippedSprayDown = () => {
    let desiredStage = "change_filter_options"
    dispatch(setBladeCleanseSubtaskStage({taskId: task.id, stage: desiredStage, callback: (success) => {
      if (success)  {
        SetSubTaskInfo({...subTaskInfo, stage: desiredStage})
        transitionToNextStep(["filter_change_options", null])
      }
    }}))  
  }

  const [sprayDownConfirmedRuntimeInfoUpdateCompleted, SetSprayDownConfirmedRuntimeInfoUpdateCompleted] = React.useState(false)
  React.useEffect(() => {
    if (sprayDownConfirmedRuntimeInfoUpdateCompleted) {
      SetSprayDownConfirmedRuntimeInfoUpdateCompleted(false)

      let desiredStage = "cleaning_out"
      dispatch(setBladeCleanseSubtaskStage({taskId: task.id, stage: desiredStage, callback: (success) => {
        if (success)  {
          SetSubTaskInfo({...subTaskInfo, stage: desiredStage})
          transitionToNextStep(["cleaning_out", null])
        }
      }}))
    }
  }, [sprayDownConfirmedRuntimeInfoUpdateCompleted, subTaskInfo, task])

  const confirmedSprayDown = () => {
    dispatch(setBladeRuntimeProperty({
      bladeId: subTaskInfo["blade_id"],
      properties: {[subTaskInfo["for_reservoir"] + "_reservoir_clean_state"]: "clean_out"},
      callback: (success) => {
        if (success)  {
          SetSprayDownConfirmedRuntimeInfoUpdateCompleted(true)
        }
      }
    }))
  }



  const [sprayDownCompletedRuntimeInformationUpdateCompleted, SetSprayDownCompletedRuntimeInformationUpdateCompleted] = React.useState(false)
  React.useEffect(() => {
    if (sprayDownCompletedRuntimeInformationUpdateCompleted) {
      SetSprayDownCompletedRuntimeInformationUpdateCompleted(false)

      let desiredStage = "change_filter_options"
      dispatch(setBladeCleanseSubtaskStage({taskId: task.id, stage: desiredStage, callback: (success) => {
        if (success)  {
          SetSubTaskInfo({...subTaskInfo, stage: desiredStage})
          transitionToNextStep(["filter_change_options", null])
        }
      }}))
    }
  }, [sprayDownCompletedRuntimeInformationUpdateCompleted, subTaskInfo, task])
  const completedSprayDown = () => {
    dispatch(setBladeRuntimeProperty({
      bladeId: subTaskInfo["blade_id"],
      properties: {[subTaskInfo["for_reservoir"] + "_reservoir_clean_state"]: "clean_out_complete"},
      callback: (success) => {
        if (success)  {
          SetSprayDownCompletedRuntimeInformationUpdateCompleted(true)
        }
      }
    }))
  }




  const skippedFilterChange = () => {
    let desiredStage = "cleanse_bath_options"
    dispatch(setBladeCleanseSubtaskStage({taskId: task.id, stage: desiredStage, callback: (success) => {
      if (success)  {
        SetSubTaskInfo({...subTaskInfo, stage: desiredStage})
        transitionToNextStep(["cleanse_bath_options", null])
      }
    }}))  
  }

  const confirmedFilterChange = () => {
    let desiredStage = "changing_filter"
    dispatch(setBladeCleanseSubtaskStage({taskId: task.id, stage: desiredStage, callback: (success) => {
      if (success)  {
        SetSubTaskInfo({...subTaskInfo, stage: desiredStage})
        transitionToNextStep(["changing_filter", null])
      }
    }}))  
  }

  const filterChangeCompleted = () => {
    let desiredStage = "cleanse_bath_options"
    dispatch(setBladeCleanseSubtaskStage({taskId: task.id, stage: desiredStage, callback: (success) => {
      if (success)  {
        SetSubTaskInfo({...subTaskInfo, stage: desiredStage})
        transitionToNextStep(["cleanse_bath_options", null])
      }
    }}))  
  }




  const bathSkipped = () => {
    //complete task
  }



  const [initateBathRuntimeInformationUpdateCompleted, SetInitateBathRuntimeInformationUpdateCompleted] = React.useState(false)
  React.useEffect(() => {
    if (initateBathRuntimeInformationUpdateCompleted) {
      SetInitateBathRuntimeInformationUpdateCompleted(false)

      let desiredStage = "full_cleanse"
      dispatch(setBladeCleanseSubtaskStage({taskId: task.id, stage: desiredStage, callback: (success) => {
        if (success)  {
          SetSubTaskInfo({...subTaskInfo, stage: desiredStage})
          transitionToNextStep(["full_cleanse", null])
        }
      }}))
    }
  }, [initateBathRuntimeInformationUpdateCompleted, subTaskInfo, task])
  const bathConfirmed = (bathDuration) => {
    dispatch(setBladeRuntimeProperty({
      bladeId: subTaskInfo["blade_id"],
      properties: {
        [subTaskInfo["for_reservoir"] + "_reservoir_clean_state"]: "full_cleanse",
        [subTaskInfo["for_reservoir"] + "_reservoir_bath_duration"]: bathDuration
      },
      callback: (success) => {
        if (success)  {
          SetInitateBathRuntimeInformationUpdateCompleted(true)
        }
      }
    }))
  }



  const stopBathEarly = () => {
    dispatch(setBladeRuntimeProperty({
      bladeId: subTaskInfo["blade_id"],
      properties: {
        [subTaskInfo["for_reservoir"] + "_reservoir_bath_duration"]: 0,
      },
      callback: (success) => {
        if (success)  {
          //SetInitateBathRuntimeInformationUpdateCompleted(true)
        }
      }
    }))
  }



  
  const [cleanseMarkedAsCompletedUpdateCompleted, SetCleanseMarkedAsCompletedUpdateCompleted] = React.useState(false)
  React.useEffect(() => {
    if (cleanseMarkedAsCompletedUpdateCompleted) {
      SetCleanseMarkedAsCompletedUpdateCompleted(false)

      dispatch(markTaskAsCompleted({taskId: task.id, params: {}, callback: (success) => {
          if (success)    {
            onCloseTask()
          }
      }}))
    }
  }, [cleanseMarkedAsCompletedUpdateCompleted])
  const cleanseFinished = () => {
    
    dispatch(markGrowZoneCleanseAsCompleted({bladeId: subTaskInfo["blade_id"], zoneUID: activeBladeZone.uid, forReservoir: subTaskInfo["for_reservoir"], taskId: task.id, callback: (success) => {
      if (success)  {
        SetSubTaskInfo({...subTaskInfo, stage: "completed"})
        SetCleanseMarkedAsCompletedUpdateCompleted(true)
      }
    }}))
  }



  /* Walkthrough requirements */
  let [currentWalkthroughStep, SetCurrentWalkthroughStep] = React.useState(["spray_down_options", null])
  let [previousWalkthroughStep, SetPreviousWalkthroughStep] = React.useState(null)
  let [nextWalkthroughStep, SetNextWalkthroughStep] = React.useState(null)
  const walkthroughTransitionToPreviousStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })
  const walkthroughTransitionToNextStepCompletedCallback = React.useCallback(() =>  {
      SetPreviousWalkthroughStep(null)
      SetNextWalkthroughStep(null)
  })

  const transitionToPreviousStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetPreviousWalkthroughStep(stepKeys)
  }
  const transitionToNextStep = (stepKeys) =>  {
      SetCurrentWalkthroughStep(stepKeys)
      SetNextWalkthroughStep(stepKeys)
  }




  const loadWalkthroughStep = (stepKeys) =>    {
      const stepKey = stepKeys[0]
      const subStepKey = stepKeys[1]
      //console.log(stepKeys)
      switch (stepKey)    {
        case "spray_down_options":
          return (<Blade_CleanseTask_SprayDownOptionsStep
            task={task}
            skipSprayDownCallback={skippedSprayDown}
            confirmSprayDownCallback={confirmedSprayDown}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 
    
        case "cleaning_out":
          return (<Blade_CleanseTask_CleaningOutStep
            task={task}
            completedSprayDownCallback={completedSprayDown}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 
        
        case "filter_change_options":
          return (<Blade_CleanseTask_FilterChangeOptionsStep
            task={task}
            skipFilterChangeCallback={skippedFilterChange}
            confirmFilterChangingCallback={confirmedFilterChange}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 
            

        case "changing_filter":
          return (<Blade_CleanseTask_ChangeFilterStep
            task={task}
            confirmFilterChangedCallback={filterChangeCompleted}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 
        

        case "cleanse_bath_options":
          return (<Blade_CleanseTask_CleanseBathOptionsStep
            task={task}
            skipBathCallback={bathSkipped}
            confirmBathCleanseCallback={bathConfirmed}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 

        case "full_cleanse":
          return (<Blade_CleanseTask_FullCleanseStep
            task={task}
            blade={activeBlade}
            subTaskInfo={subTaskInfo}
            stopBathEarlyCallback={stopBathEarly}
            finishedCleanseCallback={cleanseFinished}
            transitionToPreviousStep={transitionToPreviousStep} 
            transitionToNextStep={transitionToNextStep} />) 

        default:
            return <></>
      }
  }


  return (<>
    <Walkthrough
      title="Cleanse"
      subHeaderContent={<div className="Text-AltSubHeader">{activeZoneName}</div>}
      size="full_screen"
      closeCallback={closeTask}
      hideMainSeparators={true}
      initialWalkthroughStep={getActiveStage()}
      transitionToWalkthroughStep={nextWalkthroughStep}
      transitionBackToWalkthroughStep={previousWalkthroughStep}
      buildWalkthroughStepCallback={loadWalkthroughStep}
      transitionPreviousCompletedCallback={walkthroughTransitionToPreviousStepCompletedCallback}
      transitionNextCompletedCallback={walkthroughTransitionToNextStepCompletedCallback}

    />
  </>)
} 

export default Blade_CleanseTask