import React, { useEffect, useState } from 'react';
import { FormatDurationToShortString } from '../helpers';


const Countdown = ({remainingTime = null, fromTime = null}) => {
  
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let timeToUse = 0
  if (remainingTime !== null) {
    timeToUse = remainingTime
  }else if (fromTime !== null)  {
    timeToUse = Math.floor(Math.max(0, fromTime - (new Date().getTime() / 1000)))
  }

  return (
    <div className={"Countdown"}>
      {FormatDurationToShortString(timeToUse, true)}
    </div>
  )
} 

Countdown.defaultProps = {
  content: "",
  status: "Neutral",
  color: false, 
  fontSize: 12,
  size: "Medium"
}


export default Countdown